<template>
    <div class="header_space">
        <input type="hidden" name="bucket" :value="header.title" />

        <div v-if="loadingStatus === 'pending'" class="loading_image">
            <h1 class="main_font">
                {{ loadingText }}
            </h1>
            <img src="../assets/loading.gif" />
        </div>
        <div v-else-if="loadingStatus === 'done'" class="">
            <form
                ref="form"
                :action="uploadForm.action"
                :method="uploadForm.method"
                :enctype="uploadForm.enctype"
                @submit="gotoFiles"
            >
                <input
                    v-for="item in uploadForm.hiddenInputs"
                    :key="item.name"
                    class="main_button"
                    :name="item.name"
                    :type="item.type"
                    :value="item.value"
                />

                <table width="100%">
                    <tr>
                        <td class="upload_table_data">
                            <label class="files_button main_font">
                                <input
                                    type="file"
                                    name="file"
                                    @change="updateChosenFile"
                                />
                                Choose a file....
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="upload_table_data">
                            <h2 class="main_font">
                                {{ chosenFile }}
                            </h2>
                        </td>
                    </tr>
                    <tr>
                        <td class="upload_table_data">
                            <label class="files_button main_font">
                                <input
                                    class="files_button"
                                    type="submit"
                                    value=""
                                />
                                Upload to HypeIFC
                            </label>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
import invokeS3Upload from '../util/api/invokeS3Upload';
import readCookie from '../util/readCookie';

const log = (message) => console.log('Upload:', message);

// TODO: how do you submit the form, then go to 'Files'?
// probably simply by sending the form via axios and then move to the other page
function gotoFiles() {
    log('gotoFiles');
    //this.$router.push({ name: 'Files' });
}

function updateChosenFile(e) {
    log(`updateChosenFile ${e.srcElement.files[0].name}`);
    this.chosenFile = e.srcElement.files[0].name;
}

function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

export default {
    props: {
        objectKey: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            loadingStatus: null,
            loadingText: '',
            semantics: {},
            uploadForm: {},
            chosenFile: 'no file',
            header: {
                title: 'Upload',
            },
        };
    },

    async mounted() {
        log('upload');
        this.setHeader();

        const idToken = readCookie('id_token');

        if (!idToken) {
            log('not signed in');
            return;
        }

        this.loadingStatus = 'pending';

        this.loadingText = 'preparing your files...';
        this.uploadForm = await invokeS3Upload();

        this.loadingStatus = 'done';
    },

    methods: {
        gotoFiles,
        setHeader,
        updateChosenFile,
    },
};
</script>

<style scoped>
input[type='file'],
input[type='submit'] {
    display: none;
}

.upload_table_data {
    padding-top: 50px;
    padding-bottom: 50px;
}
</style>
